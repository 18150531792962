import React, { useContext, useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { makeStyles, Button, Typography, FormControlLabel, Grid, Checkbox, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { GlobalContext } from '../../global-context';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  termsLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  action: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const Payment = ({ setAppointmentToPaying, clientSecret, paymentFailureReason = null }) => {
  const cls = useStyles();
  const ctx = useContext(GlobalContext);
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAgreed, setIsAgreed] = React.useState(false);

  const handleCheckboxChange = event => {
    setIsAgreed(event.target.checked); // Ensure this updates correctly
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    try {
      if (setAppointmentToPaying) await setAppointmentToPaying(null);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/${ctx.id}`,
        },
      });

      // If there's an error, handle it
      if (error) {
        console.error('Payment error:', error);
        setMessage(error.message || 'An unexpected error occurred.');
      }
      // Do NOT handle success case here - it will be handled by the redirect
    } catch (err) {
      console.error('Payment processing error:', err);
      setMessage('An unexpected error occurred while processing the payment.');
    } finally {
      setIsLoading(false);
    }
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  useEffect(() => {
    if (!stripe) return;

    stripe.retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        if (paymentIntent.status === 'requires_payment_method') {
          // This is not an error - it's the sate of the payment intent before the form is completed
          setMessage(null);
        } else if (paymentFailureReason) {
          setMessage('An error occurred while processing your last payment. Please try again.');
        }
      })
      .catch(error => {
        console.error('Error retrieving payment intent:', error);
      });
  }, [stripe, clientSecret]);

  return (
    <>
      <Grid container spacing={2}>
        <div className={cls.paperWhite}>
          <Grid item xs={12}>
            <Alert severity='warning' style={{ marginBottom: '16px' }}>
              Your appointment will not be confirmed until payment is made. If a driver arives and is unable to move
              your vehicle due to your absence or if the vehicle is deemed unsafe to drive, a cancellation fee may be
              charged.
            </Alert>
          </Grid>

          <Grid item xs={12}>
            <form id='payment-form' onSubmit={handleSubmit}>
              <PaymentElement id='payment-element' options={paymentElementOptions} />

              <FormControlLabel
                control={<Checkbox checked={isAgreed} onChange={handleCheckboxChange} color='primary' />}
                label={
                  <Typography variant='body2'>
                    I understand the terms and conditions of this service.{' '}
                    <span className={cls.termsLink} onClick={() => window.open('/terms-and-conditions', '_blank')}>
                      Review Terms and Conditions
                    </span>
                  </Typography>
                }
              />

              <Button
                variant='contained'
                color='primary'
                size='large'
                disabled={!isAgreed}
                fullWidth
                type='submit'
                style={{ boxShadow: 'none', marginTop: '16px' }}
              >
                {isLoading ? (
                  <CircularProgress
                    size='1rem'
                    style={{
                      marginLeft: '0px',
                      color: '#fff',
                    }}
                  />
                ) : (
                  'Confirm and Pay'
                )}
              </Button>

              {message && (
                <Alert severity='error' style={{ marginTop: '16px' }}>
                  {message}
                </Alert>
              )}
            </form>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default Payment;
